export const menuItems = [
	{ label: "Home", href: "/" },
	{
		label: "Calendar",
		href: "/calendar",
		subItems: [
			{ label: "Calendar", href: "/calendar" },
			{ label: "Festivals", href: "/festivals" },
		],
	},
	{
		label: "Vision",
		href: "/team",
		subItems: [
			{ label: "Vision", href: "/coming-soon" },
			{ label: "Team", href: "/team" },
		],
	},
	{ label: "Contact Us", href: "/contact-us" },
];

export const NewMenuItems = [
	{
		id: 1,
		title: "About Us",
		path: "/about-us",
		subItems: [
			{
				id: 21,
				title: "Our Vision",
				path: "/our-vision",
				subItems: null,
			},
			{
				id: 22,
				title: "Our Mission",
				path: "/our-mission",
				subItems: null,
			},
			{ id: 23, title: "Our Team", path: "/team", subItems: null },
			{
				id: 24,
				title: "Our Policy",
				path: "/our-policy",
				subItems: null,
			},
		],
	},
	{
		id: 3,
		title: "Limbu Heritage",
		path: "/limbu-heritage",
		subItems: [
			{ id: 31, title: "History", path: "/history", subItems: null },
			{ id: 32, title: "Costume", path: "/costume", subItems: null },
			{ id: 33, title: "Festival", path: "/festival", subItems: null },
			{
				id: 34,
				title: "Sacred Sites",
				path: "/sacred-sites",
				subItems: null,
			},
			{ id: 35, title: "Language", path: "/language", subItems: null },
			{ id: 36, title: "Script", path: "/script", subItems: null },
			{ id: 37, title: "Mundum", path: "/mundum", subItems: null },
			{ id: 38, title: "Art", path: "/art", subItems: null },
			{ id: 39, title: "Music", path: "/music", subItems: null },
			{ id: 310, title: "Dance", path: "/dance", subItems: null },
			{ id: 311, title: "Food", path: "/food", subItems: null },
			{
				id: 312,
				title: "Personalities",
				path: "/personalities",
				subItems: null,
			},
		],
	},
	{
		id: 4,
		title: "Publications",
		path: "/publications",
		subItems: [
			{
				id: 41,
				title: "Miscellaneous",
				path: "/publications/miscellaneous",
				subItems: null,
			},
		],
	},
	{ id: 5, title: "News", path: "/news", subItems: null },
	{ id: 6, title: "Calendar", path: "/calendar", subItems: null },
	{ id: 7, title: "Donate", path: "/donate", subItems: null },
	{ id: 8, title: "Contact Us", path: "/contact-us", subItems: null },
];
