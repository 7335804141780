import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import Modal from "./MenuModal";
import useWindowSize from "../../hooks/useWindowSize";
import { NewMenuItems } from "../../data/menu";

function Menu({ className }) {
	const [isOpen, setIsOpen] = useState(false);
	const [isActiveDropdown, setIsActiveDropdown] = useState(null);
	const { width } = useWindowSize();
	const isMobile = width <= 768;

	const menuList = NewMenuItems;

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	const toggleDropdown = (id) => {
		setIsActiveDropdown(isActiveDropdown === id ? null : id);
	};

	// Ref for the dropdown container
	const dropdownRef = useRef(null);

	const handleClickOutside = useCallback((event) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target)
		) {
			setIsActiveDropdown(null);
		}
	}, []);

	// Close dropdown when clicking outside
	useEffect(() => {
		// Attach the event listener
		document.addEventListener("mousedown", handleClickOutside);

		// Cleanup the event listener
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const renderMenuItems = (items) => (
		<>
			{items.map((item) => {
				const dropdownClassName = `absolute mt-12 grid ${
					Array.isArray(item.subItems) && item.subItems.length >= 10
						? "grid-cols-2"
						: "grid-cols-1"
				} gap-4 border border-gray-300 bg-white shadow-lg rounded-lg p-2`;

				return (
					<div key={item.id} className="flex font-medium px-4">
						{Array.isArray(item.subItems) ? (
							<>
								<button
									onClick={() => toggleDropdown(item.id)}
									className="hover:text-red-400 flex items-center"
								>
									{item.title}
									<svg
										className="w-4 h-4 ml-1"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M19 9l-7 7-7-7"
										/>
									</svg>
								</button>
								{isActiveDropdown === item.id && (
									<div
										ref={dropdownRef}
										className={dropdownClassName}
									>
										{item.subItems.map((subItem) => (
											<Link
												key={subItem.id}
												to={subItem.path}
												className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md font-medium"
											>
												{subItem.title}
											</Link>
										))}
									</div>
								)}
							</>
						) : (
							<Link to={item.path} className="hover:text-red-400">
								{item.title}
							</Link>
						)}
					</div>
				);
			})}
		</>
	);

	return (
		<nav className={`flex flex-wrap  ${className}`}>
			<div className="hidden md:flex flex-wrap navbar">
				{renderMenuItems(menuList)}
			</div>
			<div className="md:hidden flex  items-center w-auto">
				<div className="flex  justify-start w-auto px-3">
					<IoMenu
						size={40}
						onClick={toggleMenu}
						className="cursor-pointer"
					/>
				</div>
			</div>
			{isOpen && (
				<Modal
					isOpen={isOpen}
					closeModal={toggleMenu}
					menuList={menuList}
					renderMenuItems={renderMenuItems}
				/>
			)}
		</nav>
	);
}

export default Menu;
